import { ElementType, HTMLAttributes } from 'react'

import useButtonsGroup from '@Hooks/useButtonsGroup'

import Button, { ButtonProps } from '../Button'
import * as styles from '../styles'

type Variant = 'NORMAL' | 'IN_A_CARD' | 'BOTTOM_ACTIONS'

export type ButtonsGroupProps<
  PrimaryComponentType extends ElementType = 'button',
  SecondaryComponentType extends ElementType = 'button'
> = {
  primary: ButtonProps<PrimaryComponentType>
  secondary?: ButtonProps<SecondaryComponentType>
  variant?: Variant
} & HTMLAttributes<HTMLDivElement>

// TODO https://jira.vsct.fr/browse/IVTS-47905 revoir les styles pour l'affichage des boutons
const ButtonsGroup = <
  PrimaryComponentType extends ElementType = 'button',
  SecondaryComponentType extends ElementType = 'button'
>({
  primary,
  secondary,
  variant = 'NORMAL',
  ...rest
}: ButtonsGroupProps<PrimaryComponentType, SecondaryComponentType>): JSX.Element => {
  const { overriddenWidth, firstButtonRef, secondButtonRef } = useButtonsGroup()

  return (
    <div
      {...rest}
      css={[
        styles.buttonsGroup,
        variant === 'IN_A_CARD' && styles.buttonsGroupInACard,
        variant === 'BOTTOM_ACTIONS' && styles.bottomActions,
      ]}
    >
      {/* Primary button is first in dom for a11y but displayed on the right when horizontal */}
      <Button
        component={'button' as ElementType}
        ref={firstButtonRef}
        css={[
          styles.buttonInGroup,
          variant === 'IN_A_CARD' && styles.fullWidth,
          overriddenWidth && styles.customWidth(overriddenWidth),
        ]}
        {...primary}
      />
      {secondary && (
        <Button
          component={'button' as ElementType}
          ref={secondButtonRef}
          css={[
            styles.buttonInGroup,
            variant === 'IN_A_CARD' && styles.fullWidth,
            overriddenWidth && styles.customWidth(overriddenWidth),
          ]}
          {...secondary}
          variant="secondary"
        />
      )}
    </div>
  )
}

export default ButtonsGroup
