import { ElementType, FC } from 'react'
import { CardContent, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import ExternalLink from '@DS/components/forms/links/externalLink/ExternalLink'
import LinkButton, { LinkButtonProps } from '@DS/components/forms/links/linkButton/LinkButton'
import BasicCard, { BasicCardProps } from '@DS/components/foundations/shapes/card/basic/BasicCard'
import { useIsInModal } from '@DS/components/navigation/modal/ModalContext'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { getColorBySeverity } from '@DS/utils/severity'
import { useIsMediumUp } from '@Hooks/useResponsive'

import MeaIcon, { MeaIconProps } from '../meaIcon/MeaIcon'

import { ActionVariantProps, Description } from './InfoMessageCard.types'
import { checkDescriptionIsString } from './InfoMessageCard.utils'
import * as styles from './styles'

export type InfoMessageCardProps = ActionVariantProps & {
  title?: string
  titleComponent?: ElementType
  description?: Description
  isSmallModeDisplayForced?: boolean
  isDisplayFullDescriptionForced?: boolean
} & Pick<MeaIconProps, 'severity'> &
  Pick<BasicCardProps, 'background'>

const InfoMessageCard: FC<InfoMessageCardProps> = ({
  background = 'paper',
  title,
  titleComponent = 'p',
  description,
  hasToDisplayFullDescription = false,
  isDisplayFullDescriptionForced = false,
  isSmallModeDisplayForced = false,
  actionLabel,
  severity,
  href,
  isExternalLink = false,
  onClick,
  ...rest
}) => {
  const theme = useTheme()
  const isDesktop = useIsMediumUp(theme)
  const isInModal = useIsInModal()
  const isSmallModeDisplay = isInModal || !isDesktop || isSmallModeDisplayForced

  const action = actionLabel && (
    <ConditionalWrapper
      condition={isExternalLink}
      renderWrapper={(children) => (
        <ExternalLink href={href} css={styles.action(!isSmallModeDisplay)}>
          {children}
        </ExternalLink>
      )}
      renderDefaultWrapper={(children) => (
        <LinkButton
          {...(href && ({ component: 'a' as ElementType, href } satisfies LinkButtonProps<'a'>))}
          css={styles.action(!isSmallModeDisplay)}
          onClick={onClick}
          withIcon
        >
          {children}
        </LinkButton>
      )}
    >
      {actionLabel}
    </ConditionalWrapper>
  )

  return (
    <BasicCard {...rest} background={background} borderColor={getColorBySeverity(theme, severity)}>
      <CardContent css={styles.flexContainer}>
        <div css={styles.content}>
          <div css={styles.flexContainer}>
            <MeaIcon css={styles.icon(!isSmallModeDisplay)} severity={severity} />
            <div>
              <div css={styles.textContainer}>
                {title && (
                  <Typography css={styles.title(severity, theme)} variant="body1" component={titleComponent}>
                    {title}
                  </Typography>
                )}
                {checkDescriptionIsString(description) ? (
                  <Typography
                    {...(!(hasToDisplayFullDescription || isDisplayFullDescriptionForced) &&
                      actionLabel && { css: styles.description })}
                  >
                    {description}
                  </Typography>
                ) : (
                  description
                )}
              </div>
              {isSmallModeDisplay && action}
            </div>
          </div>
        </div>
        {!isSmallModeDisplay && !!action && <div css={styles.buttonWrapper}>{action}</div>}
      </CardContent>
    </BasicCard>
  )
}

export default InfoMessageCard
